.footer-container {
    background-image: url('./img//footerbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #1b2f3e;
}

.footer-inner-container {
    width: 82%;
    margin: auto;
}

.footer-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-text {
    color: #b8b8b8 !important;
    font-size: 0.9rem !important;
    font-family: Montserrat !important;
}

.footer-highlight-text {
    color: #096bd8;
    text-decoration: none;
    font-family: Montserrat !important;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
    .footer-container {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .footer-content-container {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .footer-text {
        color: #b8b8b8 !important;
        font-size: 0.7rem !important;
        line-height: 1.5rem !important;
    }
}
