.ReactGridGallery_tile {
    background-color: white !important;
}

.ReactGridGallery_tile-viewport {
    border-radius: 15px !important;
}

.ReactGridGallery {
    margin-left: 3rem;
}

/* img {
  margin-left: 0px !important;
  border-radius: 15px ;
  object-fit:fill;
} */
.gallery-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.gallery-inner-container {
    width: 82%;
    margin: auto;
}

.gallery-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-text-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.gallery-text {
    font-size: 0.8rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    color: #096bd8 !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.gallery-card-container {
    text-align: center;
    border: 1px solid #ccc;
    padding: 1rem 2.5rem;
}

.gallery-cards-container {
    display: flex;
    flex-direction: column;
}

.gallery-card-container {
    border: solid 1px #ccc;
    height: 140px;
    min-width: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1240px) {
    .gallery-cards-container {
        display: flex;
        flex-direction: column;
    }

    .gallery-card-container {
        height: 100px;
        min-width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media only screen and (max-width: 991px) {
    .gallery-cards-container {
        display: flex;
        flex-direction: column;
    }

    .gallery-card-container {
        height: 140px;
        min-width: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media only screen and (max-width: 767px) {
    .gallery-large-text {
        font-size: 2rem !important;
        text-align: center !important;
        font-weight: 600 !important;
        line-height: 2rem !important;
    }

    .gallery-cards-container {
        display: flex;
        flex-direction: column;
    }

    .gallery-card-container {
        height: 100px;
        min-width: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
  