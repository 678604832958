.working-process-container {
    background-image: url('./img//processbg.jpg');
    background-size: 100% 100%;
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.working-process-inner-container {
    width: 82%;
    margin: auto;
}

.working-process-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.working-process-text-line {
    height: 2.5px;
    width: 40px;
    background: white;
    display: inline-block;
}

.working-process-text {
    font-size: 0.8rem !important;
    color: white !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.working-process-large-text-container {
    margin-top: 1.5rem;
}

.working-process-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    color: white !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.working-process-cards-container {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
}

.working-process-card-container {
    color: white;
    text-align: center;
}

.working-process-card-icon {
    font-size: 3rem;
    padding: 2.8rem;
    background-color: #318bf0;
    border-radius: 5px;
}

.working-process-card-heading-text-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.working-process-card-heading-text {
    font-size: 1.4rem !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}

.working-process-card-body-text {
    font-size: 0.9rem !important;
    line-height: 1.8rem !important;
    font-family: Montserrat !important;
    font-weight: 500 !important;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 991px) {
    .working-process-cards-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
    }
}

@media only screen and (max-width: 767px) {
    .working-process-large-text {
        font-size: 2rem !important;
        line-height: 2.5rem !important;
    }

    .working-process-cards-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3rem;
    }
}
