.header-main-container {
    overflow: hidden;
}

.header-appbar {
    background-color: white !important;
    color: #333 !important;
    box-shadow: none !important;
}

.header-appbar-inner-container {
    min-width: 95% !important;
    margin: auto !important;
}

.header-appbar-toolbar {
    display: flex !important;
    justify-content: space-between !important;
    min-height: 100% !important;
}

.header-appbar-logo-container {
    padding: 0.6rem 0;
}

.header-appbar-logo {
    height: 60px;
    width: 60px;
}

.header-appbar-main-menu-container {
    display: flex;
    align-items: center;
}

.header-appbar-text-menu-container {
    display: flex;
    align-items: center;
}

.header-menu-item {
    text-decoration: none !important;
    color: #333 !important;
    letter-spacing: 1px !important;
    display: block !important;
    padding: 2rem 1rem !important;
    transition: all 0.2s ease-in-out !important;
    font-family: Montserrat, sans-serif !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}

.header-menu-item:hover {
    cursor: pointer !important;
    color: #4e8fe2 !important;
}

.header-menu-right-social-container {
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.header-menu-right-social-icon {
    margin: 0.3rem;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bbb;
    border-radius: 100%;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}

.header-menu-right-social-icon:hover {
    cursor: pointer;
    background-color: #4e8fe2;
    color: white;
    opacity: 1;
}

.mobile-menu-button-container {
    padding: 0.9rem !important;
    margin-right: -1.8rem !important;
}

.mobile-menu-button {
    background-color: #333 !important;
    color: white !important;
    font-size: 1.8rem !important;
    padding: 0.2rem !important;
}

.mobile-drawer-conatiner {
    width: 310px;
}

.mobile-drawer-cancle-container {
    margin: 1rem;
}

.mobile-drawer-cancle-container:hover {
    cursor: pointer;
}

.mobile-drawer-cancle-button {
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
}

.mobile-drawer-cancle-container:hover .mobile-drawer-cancle-button {
    color: red;
}

.mobile-drawer-item {
    font-family: Montserrat, sans-serif !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    color: #333 !important;
    letter-spacing: 0.2px !important;
    padding: 0.9rem 1rem !important;
    display: block !important;
    transition: all 0.3s ease-in-out !important;
}

.mobile-drawer-item:hover {
    color: #4e8fe2 !important;
    margin-left: 0.2rem !important;
}

.mobile-drawer-item-divider {
    margin: 0 1rem !important;
    opacity: 0.5 !important;
}

@media only screen and (max-width: 1250px) {
    .header-menu-item {
        font-family: Montserrat, sans-serif !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
        text-decoration: none !important;
        color: #333 !important;
        letter-spacing: 0.2px !important;
        display: block !important;
        padding: 2rem 1rem !important;
        transition: all 0.2s ease-in-out !important;
    }

    .header-menu-right-social-container {
        margin-left: 1rem;
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: 1100px) {
    .header-appbar-logo {
        height: 60px;
        width: 220px;
        margin-left: -1rem;
        margin-top: 0.5rem;
    }

    .header-menu-item {
        font-family: Montserrat, sans-serif !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
        text-decoration: none !important;
        color: #333 !important;
        letter-spacing: 0.2px !important;
        display: block !important;
        padding: 2rem 1rem !important;
        transition: all 0.2s ease-in-out !important;
    }

    .header-menu-right-social-container {
        margin-left: 0.8rem;
        display: flex;
        align-items: center;
        margin-right: -0.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .header-appbar-logo {
        height: 55px;
        width: 200px;
        margin-left: -1rem;
    }
}
