.aboutus-counter-container {
    width: 82%;
    margin: auto;
}

.aboutus-counter-inner-container {
    padding-top: 5rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
}

.aboutus-counter-item-container {
    padding: 1rem;
    text-align: center;
}

.aboutus-counter-icon-container {
    margin-bottom: 1.2rem;
}

.aboutus-counter-icon {
    font-size: 2.5rem;
    color: #096bd8;
}

.aboutus-counter-number-container {
    position: relative;
}

.aboutus-counter-number {
    color: #333 !important;
    font-size: 4rem !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    font-family: Montserrat !important;
}

.aboutus-counter-number-plus {
    position: absolute;
    top: -3.5rem;
    left: 95%;
    font-size: 2rem;
    font-weight: 500;
    color: #096bd8;
}

.aboutus-counter-text {
    color: #afafaf !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    font-family: Montserrat !important;
}

@media only screen and (max-width: 1250px) {
    .aboutus-counter-inner-container {
        grid-gap: 2rem;
    }
}

@media only screen and (max-width: 991px) {
    .aboutus-counter-inner-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .aboutus-counter-inner-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;
    }
}
