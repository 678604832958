.header-banner-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.header-banner-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('./img/banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 850px;
    width: 127%;
    position: relative;
}

.header-banner-text-container {
    width: 100%;
    position: absolute;
    top: 20%;
    left: 5%;
}

.header-banner-inner-text-container {
    margin: 0 2rem;
}

.header-banner-main-text-container {
    width: 50%;
}

.header-banner-it-text {
    font-size: 0.8rem !important;
    color: white !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
}

.header-banner-agency-text {
    font-size: 0.8rem !important;
    color: white !important;
    text-transform: uppercase !important;
    background-color: #096bd8 !important;
    padding: 0.5rem 0.6rem !important;
    border-radius: 5px !important;
    margin-left: 5px !important;
    font-weight: 800 !important;
    letter-spacing: 0.2rem !important;
}

.header-banner-large-text-container {
    margin-top: 2.5rem !important;
    margin-left: 0.2rem !important;
}

.header-banner-large-text {
    line-height: 4rem !important;
    color: white !important;
    letter-spacing: 0 !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 700 !important;
    font-size: 3.2rem !important;
}

.header-banner-buttons-container {
    margin-top: 2.5rem !important;
    display: flex;
}

.header-banner-learn-more-button {
    padding: 1rem 2rem !important;
    background-color: #096bd8 !important;
    color: white !important;
    transition: all 0.3s ease-in-out !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
}

.header-banner-learn-more-button:hover {
    padding: 1rem 2rem !important;
    background-color: #096bd8 !important;
    color: white !important;
    opacity: 0.8 !important;
}

.header-banner-how-we-work-button {
    padding: 1rem 2rem !important;
    background-color: white !important;
    margin-left: 1rem !important;
    transition: all 0.3s ease-in-out !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
}

.header-banner-buttons-icon {
    margin-left: 0.6rem;
    margin-top: 0.1rem;
}

.header-banner-how-we-work-button:hover {
    background-color: #096bd8 !important;
    color: white !important;
}

@media only screen and (max-width: 1250px) {
    .header-banner-main-text-container {
        width: 60%;
    }
}

@media only screen and (max-width: 991px) {
    .header-banner-background {
        background-size: 60% 130%;
        min-height: 600px;
        width: 400%;
    }

    .header-banner-text-container {
        left: 0;
    }

    .header-banner-main-text-container {
        width: 20%;
        margin-left: 4rem;
    }

    .header-banner-large-text {
        font-size: 3rem !important;
        line-height: 4rem !important;
        letter-spacing: -0.1rem !important;
        font-weight: 700 !important;
    }
}

@media only screen and (max-width: 767px) {
    .header-banner-background {
        background-size: cover;
        min-height: 600px;
        width: 400%;
    }

    .header-banner-text-container {
        width: 100%;
        position: absolute;
        top: 10%;
        left: 0.5%;
    }

    .header-banner-inner-text-container {
        margin: 0 1rem;
    }

    .header-banner-main-text-container {
        width: 23%;
        margin-left: 0;
    }

    .header-banner-large-text {
        font-size: 2rem !important;
        line-height: 2.8rem !important;
        letter-spacing: 2px !important;
        font-weight: 700 !important;
    }

    .header-banner-buttons-container {
        margin-top: 2rem !important;
        display: flex;
        flex-direction: column;
    }

    .header-banner-how-we-work-button {
        margin-left: 0 !important;
        margin-top: 1rem !important;
    }
}
