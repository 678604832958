.MuiContainer-maxWidthLg {
    max-width: 1222px !important;
}

.MuiTypography-h6 {
    font-weight: 600 !important;
}

.MuiButton-containedPrimary {
    background-color: #2c74d6 !important;
}

.job-name .MuiTypography-body1 {
    font-size: 2.6rem !important;
    font-weight: 600 !important;
    letter-spacing: 0.00938em !important;
    line-height: 2.5rem !important;
    margin-top: 11px !important;
    /* transition: all .4s ease !important; */
}

.job-specification .MuiTypography-body1 {
    font-family: system-ui !important;
    font-weight: 100 !important;
}

.job-name-on-scroll .MuiTypography-body1 {
    font-size: 2.5rem !important;
    font-weight: 700 !important;
    letter-spacing: 0.00938em !important;
}

.showed-on-scroll-grid .MuiContainer-root {
    /* padding-top: 24px !important; */
}

.job-name-on-scroll {
    transition: all .4s ease !important;
}

.text-font-for-details {
    font-size: 15px !important;
    line-height: 30px !important;
    color: #696969 !important;
    font-family: Montserrat !important;
    font-weight: 400 !important;
}

.heading-of-details {
    font-size: 0.8rem !important;
    color: black !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.mail-address-details {
    font-size: 1rem !important;
    color: white !important;
    margin-top: 12px !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}