.domains-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.domains-inner-container {
    width: 82%;
    margin: auto;
}

.domains-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.domains-text-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.domains-text {
    font-size: 0.8rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    color: #096bd8 !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.domains-large-text-container {
    margin-top: 1.5rem;
}

.domains-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.domains-cards-container {
    margin-top: 2rem;
}

.domains-cards-inner-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1.5rem;
}

.domains-card-container {
    text-align: center;
    background-color: #f4f9fc;
    padding: 1rem;
    transition: all 0.2s ease-in-out;
}

.domains-card-container:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.domains-card-icon {
    font-size: 4rem;
    color: #096bd8;
    padding: 1rem;
}

.domains-card-text {
    font-size: 1rem !important;
    letter-spacing: 1px !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

@media only screen and (max-width: 1250px) {
    .domains-card-icon {
        font-size: 3rem;
        color: #096bd8;
        padding: 1rem;
    }

    .domains-card-text {
        font-size: 1rem !important;
        font-weight: 600 !important;
        letter-spacing: 1px !important;
    }
}

@media only screen and (max-width: 991px) {
    .domains-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .domains-large-text {
        font-size: 1.8rem !important;
        line-height: 2rem !important;
    }

    .domains-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    }

    .domains-card-container {
        text-align: center;
        background-color: #f4f9fc;
        padding: 0.4rem;
    }

    .domains-card-icon {
        font-size: 2.5rem;
        color: #096bd8;
        padding: 1rem;
    }

    .domains-card-text {
        font-size: 0.9rem !important;
        font-weight: 600 !important;
    }
}
