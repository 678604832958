.working-process-cards-container {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 991px) {
    .working-process-cards-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
    }
}

@media only screen and (max-width: 767px) {
    .working-process-cards-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3rem;
    }
}
