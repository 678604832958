.services-container {
    background-color: #f4f9fc;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.services-inner-container {
    width: 90%;
    margin: auto;
}

.services-what-we-do-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-what-we-do-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.services-what-we-do-text {
    font-size: 0.8rem !important;
    color: #096bd8 !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.services-large-text-container {
    margin-top: 1.5rem;
}

.services-large-text-inner-container {
    width: 58%;
    margin: auto;
}

.services-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.services-cards-container {
    margin-top: 3.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
}

.services-card-container {
    background-color: white;
    padding: 2rem;
}

.services-card-image {
    width: 100%;
    height: auto;
}

.services-card-text-container {
    display: flex;
    margin-top: 1.5rem;
}

.services-card-text-icon {
    font-size: 2.4rem;
    color: #096bd8;
}

.services-card-text-inner-container {
    margin-left: 1.5rem;
    margin-top: -0.5rem;
}

.services-card-heading-text {
    font-size: 1.3rem !important;
    line-height: 2rem !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}

.services-card-body-text-container {
    margin-top: 1rem;
}

.services-card-body-text {
    color: #696969 !important;
    font-size: 0.9rem !important;
    line-height: 1.8rem !important;
    font-family: Montserrat !important;
    font-weight: 400 !important;
}

.services-card-button {
    color: #096bd8 !important;
    font-weight: 800 !important;
    margin-left: -0.5rem !important;
}

.services-card-button-icon {
    font-size: 0.7rem;
    margin-bottom: 0.1rem;
    margin-left: 0.5rem;
}

@media only screen and (max-width: 1250px) {
    .services-large-text-inner-container {
        width: 63%;
        margin: auto;
    }

    .services-large-text {
        font-size: 3rem !important;
        font-weight: 600 !important;
        line-height: 3.8rem !important;
    }

    .services-card-text-container {
        flex-direction: column;
    }

    .services-card-text-icon {
        display: block;
        padding: 1rem;
        padding-top: 0.5rem;
        text-align: center;
    }

    .services-card-text-inner-container {
        margin-left: 0rem;
        margin-top: 0rem;
        text-align: center;
    }

    .services-card-button {
        margin-left: 0rem !important;
    }
}

@media only screen and (max-width: 991px) {
    .services-large-text-inner-container {
        width: 75%;
        margin: auto;
    }

    .services-large-text {
        font-size: 2.8rem !important;
        font-weight: 600 !important;
        line-height: 3.2rem !important;
    }

    .services-card-text-container {
        flex-direction: column;
    }

    .services-card-text-icon {
        display: block;
        padding: 1rem;
        padding-top: 0.5rem;
        text-align: center;
    }

    .services-card-text-inner-container {
        margin-left: 0rem;
        margin-top: 0rem;
        text-align: center;
    }

    .services-card-button {
        margin-left: 0rem !important;
    }

    .services-cards-container {
        padding: 3rem;
        padding-top: 0rem;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .services-large-text-inner-container {
        width: 90%;
        margin: auto;
    }

    .services-large-text {
        font-size: 1.7rem !important;
        font-weight: 600 !important;
        line-height: 2.5rem !important;
    }

    .services-card-text-container {
        flex-direction: column;
    }

    .services-card-text-icon {
        display: block;
        padding: 1rem;
        padding-top: 0.5rem;
        text-align: center;
    }

    .services-card-text-inner-container {
        margin-left: 0rem;
        margin-top: 0rem;
        text-align: center;
    }

    .services-card-button {
        margin-left: 0rem !important;
    }

    .services-cards-container {
        padding: 0.5rem;
        padding-top: 0rem;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;
    }
}
