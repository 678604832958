.about-container {
    width: 96%;
    margin: auto;
    padding-top: 8rem;
}

.about-inner-container {
    display: flex;
}

.about-left-image-container {
    flex: 1;
}

.about-left-image {
    width: 100%;
    height: auto;
}

.about-right-text-container {
    flex: 1;
    margin-left: 3rem;
}

.about-aboutus-text-container {
    display: flex;
    align-items: center;
}

.about-aboutus-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.about-aboutus-text {
    font-size: 0.8rem !important;
    color: #096bd8 !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 900 !important;
}

.about-why-text-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.about-why-text {
    font-size: 2rem !important;
    line-height: 2.8rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
}

.about-why-bellow-text-container {
    margin-bottom: 1.5rem;
}

.about-why-bellow-text {
    font-size: 15px !important;
    line-height: 30px !important;
    color: #696969 !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
}

.about-accordian {
    border: none !important;
}

/* accordion css */
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 2px !important;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.accordion__button {
    background-color: #f4f4f4 !important;
    color: #444 !important;
    cursor: pointer !important;
    padding: 18px !important;
    width: 100% !important;
    text-align: left !important;
    border: none !important;
    outline: none !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    font-family: Montserrat, sans-serif !important;
}

.accordion__button:hover {
    background-color: #ddd !important;
}

.accordion__button:before {
    display: inline-block !important;
    content: '' !important;
    height: 7px !important;
    width: 7px !important;
    margin-right: 12px !important;
    margin-bottom: 1px !important;
    border-bottom: 2px solid currentColor !important;
    border-right: 2px solid currentColor !important;
    transform: rotate(-45deg) !important;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg) !important;
    margin-bottom: 5px !important;
}

[hidden] {
    display: none !important;
}

.accordion__panel {
    padding: 20px !important;
    animation: fadein 0.35s ease-in !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    color: #696969 !important;
    font-family: Montserrat, sans-serif !important;
}

@keyframes fadein {
    0% {
        opacity: 0 !important;
    }

    100% {
        opacity: 1 !important;
    }
}

/* accordian-css */

@media only screen and (max-width: 1250px) {
    .about-right-text-container {
        margin-left: 3rem;
    }

    .about-why-text {
        font-size: 2.5rem !important;
        font-weight: 600 !important;
        line-height: 3.2rem !important;
    }

    /* accordion css */
    .accordion {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 2px !important;
    }

    .accordion__item + .accordion__item {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    .accordion__button {
        background-color: #f4f4f4 !important;
        color: #444 !important;
        cursor: pointer !important;
        padding: 18px !important;
        width: 100% !important;
        text-align: left !important;
        border: none !important;
        outline: none !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
        font-family: Montserrat, sans-serif !important;
    }

    .accordion__button:hover {
        background-color: #ddd !important;
    }

    .accordion__button:before {
        display: inline-block !important;
        content: '' !important;
        height: 7px !important;
        width: 7px !important;
        margin-right: 12px !important;
        margin-bottom: 1px !important;
        border-bottom: 2px solid currentColor !important;
        border-right: 2px solid currentColor !important;
        transform: rotate(-45deg) !important;
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg) !important;
        margin-bottom: 5px !important;
    }

    [hidden] {
        display: none !important;
    }

    .accordion__panel {
        padding: 20px !important;
        animation: fadein 0.35s ease-in !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        color: #696969 !important;
        font-family: Montserrat, sans-serif !important;
    }

    @keyframes fadein {
        0% {
            opacity: 0 !important;
        }

        100% {
            opacity: 1 !important;
        }
    }
    /* accordian-css */
}

@media only screen and (max-width: 991px) {
    .about-inner-container {
        display: flex;
        flex-direction: column;
    }

    .about-left-image {
        width: 70%;
        height: auto;
    }

    .about-right-text-container {
        margin-top: 3rem;
        margin-left: 0;
    }

    .about-why-text {
        font-size: 2.6rem !important;
        font-weight: 600 !important;
        line-height: 3.5rem !important;
    }

    /* accordion css */
    .accordion {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 2px !important;
    }

    .accordion__item + .accordion__item {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    .accordion__button {
        background-color: #f4f4f4 !important;
        color: #444 !important;
        cursor: pointer !important;
        padding: 18px !important;
        width: 100% !important;
        text-align: left !important;
        border: none !important;
        outline: none !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
        font-family: Montserrat, sans-serif !important;
    }

    .accordion__button:hover {
        background-color: #ddd !important;
    }

    .accordion__button:before {
        display: inline-block !important;
        content: '' !important;
        height: 7px !important;
        width: 7px !important;
        margin-right: 12px !important;
        margin-bottom: 1px !important;
        border-bottom: 2px solid currentColor !important;
        border-right: 2px solid currentColor !important;
        transform: rotate(-45deg) !important;
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg) !important;
        margin-bottom: 5px !important;
    }

    [hidden] {
        display: none !important;
    }

    .accordion__panel {
        padding: 20px !important;
        animation: fadein 0.35s ease-in !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        color: #696969 !important;
        font-family: Montserrat, sans-serif !important;
    }

    @keyframes fadein {
        0% {
            opacity: 0 !important;
        }

        100% {
            opacity: 1 !important;
        }
    }
    /* accordian-css */
}

@media only screen and (max-width: 767px) {
    .about-container {
        padding-top: 2rem;
    }

    .about-left-image {
        width: 100%;
        height: auto;
    }

    .about-right-text-container {
        margin-top: 3rem;
        margin-left: 0;
    }

    .about-why-text {
        font-size: 1.7rem !important;
        font-weight: 600 !important;
        line-height: 2.5rem !important;
    }

    /* accordion css */
    .accordion {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 2px !important;
    }

    .accordion__item + .accordion__item {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    .accordion__button {
        background-color: #f4f4f4 !important;
        color: #444 !important;
        cursor: pointer !important;
        padding: 18px !important;
        width: 100% !important;
        text-align: left !important;
        border: none !important;
        outline: none !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
        font-family: Montserrat, sans-serif !important;
    }

    .accordion__button:hover {
        background-color: #ddd !important;
    }

    .accordion__button:before {
        display: inline-block !important;
        content: '' !important;
        height: 7px !important;
        width: 7px !important;
        margin-right: 12px !important;
        margin-bottom: 1px !important;
        border-bottom: 2px solid currentColor !important;
        border-right: 2px solid currentColor !important;
        transform: rotate(-45deg) !important;
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg) !important;
        margin-bottom: 5px !important;
    }

    [hidden] {
        display: none !important;
    }

    .accordion__panel {
        padding: 20px !important;
        animation: fadein 0.35s ease-in !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        color: #696969 !important;
        font-family: Montserrat, sans-serif !important;
    }

    @keyframes fadein {
        0% {
            opacity: 0 !important;
        }

        100% {
            opacity: 1 !important;
        }
    }
    /* accordian-css */
}
