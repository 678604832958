.technologies-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.technologies-inner-container {
    width: 82%;
    margin: auto;
}

.technologies-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.technologies-text-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.technologies-text {
    font-size: 0.8rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    color: #096bd8 !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.technologies-large-text-container {
    margin-top: 1.5rem;
}

.technologies-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.technologies-swiper-container {
    margin-top: 2rem;
}

.technologies-swiper-image {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
    .technologies-large-text {
        font-size: 2rem !important;
        text-align: center !important;
        font-weight: 600 !important;
        line-height: 2rem !important;
    }
}
