.blog-page-container {
    background-color: #f4f9fc;
}

.blog-page-inner-container {
    width: 82%;
    margin: auto;
}

.blog-page-blog-outer-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.blog-page-blog-container {
    padding: 4rem 6rem;
}

.blog-page-blog-main-heading {
    font-size: 3rem !important;
    text-align: center !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
    width: 100% !important;
    margin: auto !important;
}

.blog-page-profile-and-share-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    margin-top: 2rem;
}

.blog-page-share-container {
    margin-top: 0;
}

.blog-page-blog-title {
    font-size: 1.2rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
    line-height: 2rem !important;
}

.blog-page-blog-body {
    margin-top: 1rem !important;
    font-size: 1rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    line-height: 2rem !important;
}

.blog-page-blog-comment-profile {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.blog-page-blog-comment-profile-text-container {
    margin-left: 0.8rem;
}

.blog-page-blog-comment-profile-text {
    font-size: 0.8rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    width: 80% !important;
}

.blog-page-blog-body-share-container {
    margin-top: 2rem;
}

@media only screen and (max-width: 1250px) {
    .blog-page-blog-main-heading {
        font-size: 2rem !important;
    }
}

@media only screen and (max-width: 991px) {
    .blog-page-blog-main-heading {
        font-size: 1.5rem !important;
    }
}

@media only screen and (max-width: 767px) {
    .blog-page-inner-container {
        width: 95%;
        margin: auto;
    }

    .blog-page-blog-container {
        padding: 0 0;
    }

    .blog-page-blog-main-heading {
        font-size: 1.4rem !important;
    }

    .blog-page-profile-and-share-container {
        flex-direction: column;
        margin-top: 1.5rem;
    }

    .blog-page-share-container {
        margin-top: 0.5rem;
    }

    .blog-page-blog-title {
        font-size: 1.1rem !important;
    }

    .blog-page-blog-body {
        margin-top: 1rem !important;
        font-size: 0.9rem !important;
        font-family: Montserrat, sans-serif !important;
        font-weight: 400 !important;
    }

    .blog-page-blog-comment-profile {
        align-items: flex-start;
        flex-direction: column;
    }

    .blog-page-blog-comment-profile-text-container {
        margin-left: 0;
    }

    .blog-page-blog-comment-profile-text {
        width: 100% !important;
    }

    .blog-page-blog-body-share-container {
        text-align: center;
    }
}
