.job-labels {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px !important;
    margin: 24px 0 24px 0 !important;
    width: auto !important;
    /* height: 300px !important; */
}

.job-content {
    border: 1px solid #ccc !important;
    border-radius: 8px !important;
    padding: 16px 16px 30px !important;
    cursor: pointer !important;
}

.job-content:hover {
    background-color: #286dcb !important;
    cursor: pointer !important;
}

.job-content:hover .job-title {
    color: #ffff !important;
}

.job-content:hover .view-details-text {
    color: #fff !important;
}

.job-content:hover .job_label {
    color: #ddd !important;
}

.job-title {
    /* font-family: montserratmedium !important; */
    line-height: 1.5 !important;
    font-size: 1.5rem !important;
    height: 64px !important;
    font-weight: 600 !important;
    padding-top: 4px !important;
    color: #000 !important;
    text-decoration: none !important;
}

.job-title-on-hover {
    /* font-family: montserratmedium !important; */
    line-height: 1.5 !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    padding-top: 4px !important;
    color: #fff !important;
}

.job_label {
    height: 64px !important;
    font-size: 0.9rem !important;
    line-height: 1.45em !important;
    margin: 16px 0 !important;
    color: #444 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    font-weight: 300 !important;
    text-decoration: none !important;
}

.view-details-text {
    text-transform: uppercase !important;
    color: #286dcb !important;
    font-size: 0.9rem !important;
    line-height: 1.5em !important;
    position: absolute !important;
    /* bottom: 10px !important; */
    font-weight: 600 !important;
}

.underline-remove {
    text-decoration: none !important;
}

.go-back-btn {
    cursor: pointer;
}

.header-container {
    background-color: rgb(11, 11, 60) !important;
    color: #fff;
    display: grid;
    grid-template-columns: minmax(100%, 1200px) !important;
    margin: 0 auto !important;
    /* max-width: 1200px !important; */
}

.banner-container {
    padding: 20px 0 20px 0 !important;
}

.back-icon-height {
    font-size: 56px !important;
}

.banner-label-title {
    font-family: montserratmedium !important;
}

.header-title {
    font-size: 2.6rem !important;
    font-weight: 600 !important;
    letter-spacing: 0.00938em !important;
    line-height: 2.5rem !important;
}

.header-title.MuiTypography-body1 {
    letter-spacing: normal !important;
}

@media only screen and (max-width: 768px) {
    .job-labels {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 0.5rem;
        margin: 24px 0 24px 0 !important;
    }
}
