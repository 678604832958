html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
    font-family: Montserrat;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-main-container {
    overflow: hidden;
}

.header-appbar {
    background-color: white !important;
    color: #333 !important;
    box-shadow: none !important;
}

.header-appbar-inner-container {
    min-width: 95% !important;
    margin: auto !important;
}

.header-appbar-toolbar {
    display: flex !important;
    justify-content: space-between !important;
    min-height: 100% !important;
}

.header-appbar-logo-container {
    padding: 0.6rem 0;
}

.header-appbar-logo {
    height: 60px;
    width: 60px;
}

.header-appbar-main-menu-container {
    display: flex;
    align-items: center;
}

.header-appbar-text-menu-container {
    display: flex;
    align-items: center;
}

.header-menu-item {
    text-decoration: none !important;
    color: #333 !important;
    letter-spacing: 1px !important;
    display: block !important;
    padding: 2rem 1rem !important;
    transition: all 0.2s ease-in-out !important;
    font-family: Montserrat, sans-serif !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}

.header-menu-item:hover {
    cursor: pointer !important;
    color: #4e8fe2 !important;
}

.header-menu-right-social-container {
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.header-menu-right-social-icon {
    margin: 0.3rem;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bbb;
    border-radius: 100%;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}

.header-menu-right-social-icon:hover {
    cursor: pointer;
    background-color: #4e8fe2;
    color: white;
    opacity: 1;
}

.mobile-menu-button-container {
    padding: 0.9rem !important;
    margin-right: -1.8rem !important;
}

.mobile-menu-button {
    background-color: #333 !important;
    color: white !important;
    font-size: 1.8rem !important;
    padding: 0.2rem !important;
}

.mobile-drawer-conatiner {
    width: 310px;
}

.mobile-drawer-cancle-container {
    margin: 1rem;
}

.mobile-drawer-cancle-container:hover {
    cursor: pointer;
}

.mobile-drawer-cancle-button {
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
}

.mobile-drawer-cancle-container:hover .mobile-drawer-cancle-button {
    color: red;
}

.mobile-drawer-item {
    font-family: Montserrat, sans-serif !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    color: #333 !important;
    letter-spacing: 0.2px !important;
    padding: 0.9rem 1rem !important;
    display: block !important;
    transition: all 0.3s ease-in-out !important;
}

.mobile-drawer-item:hover {
    color: #4e8fe2 !important;
    margin-left: 0.2rem !important;
}

.mobile-drawer-item-divider {
    margin: 0 1rem !important;
    opacity: 0.5 !important;
}

@media only screen and (max-width: 1250px) {
    .header-menu-item {
        font-family: Montserrat, sans-serif !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
        text-decoration: none !important;
        color: #333 !important;
        letter-spacing: 0.2px !important;
        display: block !important;
        padding: 2rem 1rem !important;
        transition: all 0.2s ease-in-out !important;
    }

    .header-menu-right-social-container {
        margin-left: 1rem;
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: 1100px) {
    .header-appbar-logo {
        height: 60px;
        width: 220px;
        margin-left: -1rem;
        margin-top: 0.5rem;
    }

    .header-menu-item {
        font-family: Montserrat, sans-serif !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
        text-decoration: none !important;
        color: #333 !important;
        letter-spacing: 0.2px !important;
        display: block !important;
        padding: 2rem 1rem !important;
        transition: all 0.2s ease-in-out !important;
    }

    .header-menu-right-social-container {
        margin-left: 0.8rem;
        display: flex;
        align-items: center;
        margin-right: -0.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .header-appbar-logo {
        height: 55px;
        width: 200px;
        margin-left: -1rem;
    }
}

.footer-container {
    background-image: url(/static/media/footerbg.414d77e8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid #1b2f3e;
}

.footer-inner-container {
    width: 82%;
    margin: auto;
}

.footer-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-text {
    color: #b8b8b8 !important;
    font-size: 0.9rem !important;
    font-family: Montserrat !important;
}

.footer-highlight-text {
    color: #096bd8;
    text-decoration: none;
    font-family: Montserrat !important;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
    .footer-container {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .footer-content-container {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .footer-text {
        color: #b8b8b8 !important;
        font-size: 0.7rem !important;
        line-height: 1.5rem !important;
    }
}

.header-banner-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.header-banner-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/static/media/banner.ad065442.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 850px;
    width: 127%;
    position: relative;
}

.header-banner-text-container {
    width: 100%;
    position: absolute;
    top: 20%;
    left: 5%;
}

.header-banner-inner-text-container {
    margin: 0 2rem;
}

.header-banner-main-text-container {
    width: 50%;
}

.header-banner-it-text {
    font-size: 0.8rem !important;
    color: white !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
}

.header-banner-agency-text {
    font-size: 0.8rem !important;
    color: white !important;
    text-transform: uppercase !important;
    background-color: #096bd8 !important;
    padding: 0.5rem 0.6rem !important;
    border-radius: 5px !important;
    margin-left: 5px !important;
    font-weight: 800 !important;
    letter-spacing: 0.2rem !important;
}

.header-banner-large-text-container {
    margin-top: 2.5rem !important;
    margin-left: 0.2rem !important;
}

.header-banner-large-text {
    line-height: 4rem !important;
    color: white !important;
    letter-spacing: 0 !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 700 !important;
    font-size: 3.2rem !important;
}

.header-banner-buttons-container {
    margin-top: 2.5rem !important;
    display: flex;
}

.header-banner-learn-more-button {
    padding: 1rem 2rem !important;
    background-color: #096bd8 !important;
    color: white !important;
    transition: all 0.3s ease-in-out !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
}

.header-banner-learn-more-button:hover {
    padding: 1rem 2rem !important;
    background-color: #096bd8 !important;
    color: white !important;
    opacity: 0.8 !important;
}

.header-banner-how-we-work-button {
    padding: 1rem 2rem !important;
    background-color: white !important;
    margin-left: 1rem !important;
    transition: all 0.3s ease-in-out !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
}

.header-banner-buttons-icon {
    margin-left: 0.6rem;
    margin-top: 0.1rem;
}

.header-banner-how-we-work-button:hover {
    background-color: #096bd8 !important;
    color: white !important;
}

@media only screen and (max-width: 1250px) {
    .header-banner-main-text-container {
        width: 60%;
    }
}

@media only screen and (max-width: 991px) {
    .header-banner-background {
        background-size: 60% 130%;
        min-height: 600px;
        width: 400%;
    }

    .header-banner-text-container {
        left: 0;
    }

    .header-banner-main-text-container {
        width: 20%;
        margin-left: 4rem;
    }

    .header-banner-large-text {
        font-size: 3rem !important;
        line-height: 4rem !important;
        letter-spacing: -0.1rem !important;
        font-weight: 700 !important;
    }
}

@media only screen and (max-width: 767px) {
    .header-banner-background {
        background-size: cover;
        min-height: 600px;
        width: 400%;
    }

    .header-banner-text-container {
        width: 100%;
        position: absolute;
        top: 10%;
        left: 0.5%;
    }

    .header-banner-inner-text-container {
        margin: 0 1rem;
    }

    .header-banner-main-text-container {
        width: 23%;
        margin-left: 0;
    }

    .header-banner-large-text {
        font-size: 2rem !important;
        line-height: 2.8rem !important;
        letter-spacing: 2px !important;
        font-weight: 700 !important;
    }

    .header-banner-buttons-container {
        margin-top: 2rem !important;
        display: flex;
        flex-direction: column;
    }

    .header-banner-how-we-work-button {
        margin-left: 0 !important;
        margin-top: 1rem !important;
    }
}

.about-container {
    width: 96%;
    margin: auto;
    padding-top: 8rem;
}

.about-inner-container {
    display: flex;
}

.about-left-image-container {
    flex: 1 1;
}

.about-left-image {
    width: 100%;
    height: auto;
}

.about-right-text-container {
    flex: 1 1;
    margin-left: 3rem;
}

.about-aboutus-text-container {
    display: flex;
    align-items: center;
}

.about-aboutus-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.about-aboutus-text {
    font-size: 0.8rem !important;
    color: #096bd8 !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 900 !important;
}

.about-why-text-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.about-why-text {
    font-size: 2rem !important;
    line-height: 2.8rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
}

.about-why-bellow-text-container {
    margin-bottom: 1.5rem;
}

.about-why-bellow-text {
    font-size: 15px !important;
    line-height: 30px !important;
    color: #696969 !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
}

.about-accordian {
    border: none !important;
}

/* accordion css */
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 2px !important;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.accordion__button {
    background-color: #f4f4f4 !important;
    color: #444 !important;
    cursor: pointer !important;
    padding: 18px !important;
    width: 100% !important;
    text-align: left !important;
    border: none !important;
    outline: none !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    font-family: Montserrat, sans-serif !important;
}

.accordion__button:hover {
    background-color: #ddd !important;
}

.accordion__button:before {
    display: inline-block !important;
    content: '' !important;
    height: 7px !important;
    width: 7px !important;
    margin-right: 12px !important;
    margin-bottom: 1px !important;
    border-bottom: 2px solid currentColor !important;
    border-right: 2px solid currentColor !important;
    transform: rotate(-45deg) !important;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg) !important;
    margin-bottom: 5px !important;
}

[hidden] {
    display: none !important;
}

.accordion__panel {
    padding: 20px !important;
    animation: fadein 0.35s ease-in !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    color: #696969 !important;
    font-family: Montserrat, sans-serif !important;
}

@keyframes fadein {
    0% {
        opacity: 0 !important;
    }

    100% {
        opacity: 1 !important;
    }
}

/* accordian-css */

@media only screen and (max-width: 1250px) {
    .about-right-text-container {
        margin-left: 3rem;
    }

    .about-why-text {
        font-size: 2.5rem !important;
        font-weight: 600 !important;
        line-height: 3.2rem !important;
    }

    /* accordion css */
    .accordion {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 2px !important;
    }

    .accordion__item + .accordion__item {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    .accordion__button {
        background-color: #f4f4f4 !important;
        color: #444 !important;
        cursor: pointer !important;
        padding: 18px !important;
        width: 100% !important;
        text-align: left !important;
        border: none !important;
        outline: none !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
        font-family: Montserrat, sans-serif !important;
    }

    .accordion__button:hover {
        background-color: #ddd !important;
    }

    .accordion__button:before {
        display: inline-block !important;
        content: '' !important;
        height: 7px !important;
        width: 7px !important;
        margin-right: 12px !important;
        margin-bottom: 1px !important;
        border-bottom: 2px solid currentColor !important;
        border-right: 2px solid currentColor !important;
        transform: rotate(-45deg) !important;
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg) !important;
        margin-bottom: 5px !important;
    }

    [hidden] {
        display: none !important;
    }

    .accordion__panel {
        padding: 20px !important;
        animation: fadein 0.35s ease-in !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        color: #696969 !important;
        font-family: Montserrat, sans-serif !important;
    }

    @keyframes fadein {
        0% {
            opacity: 0 !important;
        }

        100% {
            opacity: 1 !important;
        }
    }
    /* accordian-css */
}

@media only screen and (max-width: 991px) {
    .about-inner-container {
        display: flex;
        flex-direction: column;
    }

    .about-left-image {
        width: 70%;
        height: auto;
    }

    .about-right-text-container {
        margin-top: 3rem;
        margin-left: 0;
    }

    .about-why-text {
        font-size: 2.6rem !important;
        font-weight: 600 !important;
        line-height: 3.5rem !important;
    }

    /* accordion css */
    .accordion {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 2px !important;
    }

    .accordion__item + .accordion__item {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    .accordion__button {
        background-color: #f4f4f4 !important;
        color: #444 !important;
        cursor: pointer !important;
        padding: 18px !important;
        width: 100% !important;
        text-align: left !important;
        border: none !important;
        outline: none !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
        font-family: Montserrat, sans-serif !important;
    }

    .accordion__button:hover {
        background-color: #ddd !important;
    }

    .accordion__button:before {
        display: inline-block !important;
        content: '' !important;
        height: 7px !important;
        width: 7px !important;
        margin-right: 12px !important;
        margin-bottom: 1px !important;
        border-bottom: 2px solid currentColor !important;
        border-right: 2px solid currentColor !important;
        transform: rotate(-45deg) !important;
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg) !important;
        margin-bottom: 5px !important;
    }

    [hidden] {
        display: none !important;
    }

    .accordion__panel {
        padding: 20px !important;
        animation: fadein 0.35s ease-in !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        color: #696969 !important;
        font-family: Montserrat, sans-serif !important;
    }

    @keyframes fadein {
        0% {
            opacity: 0 !important;
        }

        100% {
            opacity: 1 !important;
        }
    }
    /* accordian-css */
}

@media only screen and (max-width: 767px) {
    .about-container {
        padding-top: 2rem;
    }

    .about-left-image {
        width: 100%;
        height: auto;
    }

    .about-right-text-container {
        margin-top: 3rem;
        margin-left: 0;
    }

    .about-why-text {
        font-size: 1.7rem !important;
        font-weight: 600 !important;
        line-height: 2.5rem !important;
    }

    /* accordion css */
    .accordion {
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 2px !important;
    }

    .accordion__item + .accordion__item {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    .accordion__button {
        background-color: #f4f4f4 !important;
        color: #444 !important;
        cursor: pointer !important;
        padding: 18px !important;
        width: 100% !important;
        text-align: left !important;
        border: none !important;
        outline: none !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
        font-family: Montserrat, sans-serif !important;
    }

    .accordion__button:hover {
        background-color: #ddd !important;
    }

    .accordion__button:before {
        display: inline-block !important;
        content: '' !important;
        height: 7px !important;
        width: 7px !important;
        margin-right: 12px !important;
        margin-bottom: 1px !important;
        border-bottom: 2px solid currentColor !important;
        border-right: 2px solid currentColor !important;
        transform: rotate(-45deg) !important;
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg) !important;
        margin-bottom: 5px !important;
    }

    [hidden] {
        display: none !important;
    }

    .accordion__panel {
        padding: 20px !important;
        animation: fadein 0.35s ease-in !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        color: #696969 !important;
        font-family: Montserrat, sans-serif !important;
    }

    @keyframes fadein {
        0% {
            opacity: 0 !important;
        }

        100% {
            opacity: 1 !important;
        }
    }
    /* accordian-css */
}

.aboutus-counter-container {
    width: 82%;
    margin: auto;
}

.aboutus-counter-inner-container {
    padding-top: 5rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
}

.aboutus-counter-item-container {
    padding: 1rem;
    text-align: center;
}

.aboutus-counter-icon-container {
    margin-bottom: 1.2rem;
}

.aboutus-counter-icon {
    font-size: 2.5rem;
    color: #096bd8;
}

.aboutus-counter-number-container {
    position: relative;
}

.aboutus-counter-number {
    color: #333 !important;
    font-size: 4rem !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    font-family: Montserrat !important;
}

.aboutus-counter-number-plus {
    position: absolute;
    top: -3.5rem;
    left: 95%;
    font-size: 2rem;
    font-weight: 500;
    color: #096bd8;
}

.aboutus-counter-text {
    color: #afafaf !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    font-family: Montserrat !important;
}

@media only screen and (max-width: 1250px) {
    .aboutus-counter-inner-container {
        grid-gap: 2rem;
    }
}

@media only screen and (max-width: 991px) {
    .aboutus-counter-inner-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .aboutus-counter-inner-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;
    }
}

.services-container {
    background-color: #f4f9fc;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.services-inner-container {
    width: 90%;
    margin: auto;
}

.services-what-we-do-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-what-we-do-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.services-what-we-do-text {
    font-size: 0.8rem !important;
    color: #096bd8 !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.services-large-text-container {
    margin-top: 1.5rem;
}

.services-large-text-inner-container {
    width: 58%;
    margin: auto;
}

.services-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.services-cards-container {
    margin-top: 3.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
}

.services-card-container {
    background-color: white;
    padding: 2rem;
}

.services-card-image {
    width: 100%;
    height: auto;
}

.services-card-text-container {
    display: flex;
    margin-top: 1.5rem;
}

.services-card-text-icon {
    font-size: 2.4rem;
    color: #096bd8;
}

.services-card-text-inner-container {
    margin-left: 1.5rem;
    margin-top: -0.5rem;
}

.services-card-heading-text {
    font-size: 1.3rem !important;
    line-height: 2rem !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}

.services-card-body-text-container {
    margin-top: 1rem;
}

.services-card-body-text {
    color: #696969 !important;
    font-size: 0.9rem !important;
    line-height: 1.8rem !important;
    font-family: Montserrat !important;
    font-weight: 400 !important;
}

.services-card-button {
    color: #096bd8 !important;
    font-weight: 800 !important;
    margin-left: -0.5rem !important;
}

.services-card-button-icon {
    font-size: 0.7rem;
    margin-bottom: 0.1rem;
    margin-left: 0.5rem;
}

@media only screen and (max-width: 1250px) {
    .services-large-text-inner-container {
        width: 63%;
        margin: auto;
    }

    .services-large-text {
        font-size: 3rem !important;
        font-weight: 600 !important;
        line-height: 3.8rem !important;
    }

    .services-card-text-container {
        flex-direction: column;
    }

    .services-card-text-icon {
        display: block;
        padding: 1rem;
        padding-top: 0.5rem;
        text-align: center;
    }

    .services-card-text-inner-container {
        margin-left: 0rem;
        margin-top: 0rem;
        text-align: center;
    }

    .services-card-button {
        margin-left: 0rem !important;
    }
}

@media only screen and (max-width: 991px) {
    .services-large-text-inner-container {
        width: 75%;
        margin: auto;
    }

    .services-large-text {
        font-size: 2.8rem !important;
        font-weight: 600 !important;
        line-height: 3.2rem !important;
    }

    .services-card-text-container {
        flex-direction: column;
    }

    .services-card-text-icon {
        display: block;
        padding: 1rem;
        padding-top: 0.5rem;
        text-align: center;
    }

    .services-card-text-inner-container {
        margin-left: 0rem;
        margin-top: 0rem;
        text-align: center;
    }

    .services-card-button {
        margin-left: 0rem !important;
    }

    .services-cards-container {
        padding: 3rem;
        padding-top: 0rem;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .services-large-text-inner-container {
        width: 90%;
        margin: auto;
    }

    .services-large-text {
        font-size: 1.7rem !important;
        font-weight: 600 !important;
        line-height: 2.5rem !important;
    }

    .services-card-text-container {
        flex-direction: column;
    }

    .services-card-text-icon {
        display: block;
        padding: 1rem;
        padding-top: 0.5rem;
        text-align: center;
    }

    .services-card-text-inner-container {
        margin-left: 0rem;
        margin-top: 0rem;
        text-align: center;
    }

    .services-card-button {
        margin-left: 0rem !important;
    }

    .services-cards-container {
        padding: 0.5rem;
        padding-top: 0rem;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2rem;
    }
}

.domains-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.domains-inner-container {
    width: 82%;
    margin: auto;
}

.domains-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.domains-text-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.domains-text {
    font-size: 0.8rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    color: #096bd8 !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.domains-large-text-container {
    margin-top: 1.5rem;
}

.domains-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.domains-cards-container {
    margin-top: 2rem;
}

.domains-cards-inner-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1.5rem;
}

.domains-card-container {
    text-align: center;
    background-color: #f4f9fc;
    padding: 1rem;
    transition: all 0.2s ease-in-out;
}

.domains-card-container:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.domains-card-icon {
    font-size: 4rem;
    color: #096bd8;
    padding: 1rem;
}

.domains-card-text {
    font-size: 1rem !important;
    letter-spacing: 1px !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

@media only screen and (max-width: 1250px) {
    .domains-card-icon {
        font-size: 3rem;
        color: #096bd8;
        padding: 1rem;
    }

    .domains-card-text {
        font-size: 1rem !important;
        font-weight: 600 !important;
        letter-spacing: 1px !important;
    }
}

@media only screen and (max-width: 991px) {
    .domains-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .domains-large-text {
        font-size: 1.8rem !important;
        line-height: 2rem !important;
    }

    .domains-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    }

    .domains-card-container {
        text-align: center;
        background-color: #f4f9fc;
        padding: 0.4rem;
    }

    .domains-card-icon {
        font-size: 2.5rem;
        color: #096bd8;
        padding: 1rem;
    }

    .domains-card-text {
        font-size: 0.9rem !important;
        font-weight: 600 !important;
    }
}

.working-process-container {
    background-image: url(/static/media/processbg.966adba4.jpg);
    background-size: 100% 100%;
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.working-process-inner-container {
    width: 82%;
    margin: auto;
}

.working-process-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.working-process-text-line {
    height: 2.5px;
    width: 40px;
    background: white;
    display: inline-block;
}

.working-process-text {
    font-size: 0.8rem !important;
    color: white !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.working-process-large-text-container {
    margin-top: 1.5rem;
}

.working-process-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    color: white !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.working-process-cards-container {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
}

.working-process-card-container {
    color: white;
    text-align: center;
}

.working-process-card-icon {
    font-size: 3rem;
    padding: 2.8rem;
    background-color: #318bf0;
    border-radius: 5px;
}

.working-process-card-heading-text-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.working-process-card-heading-text {
    font-size: 1.4rem !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}

.working-process-card-body-text {
    font-size: 0.9rem !important;
    line-height: 1.8rem !important;
    font-family: Montserrat !important;
    font-weight: 500 !important;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 991px) {
    .working-process-cards-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
    }
}

@media only screen and (max-width: 767px) {
    .working-process-large-text {
        font-size: 2rem !important;
        line-height: 2.5rem !important;
    }

    .working-process-cards-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3rem;
    }
}

.technologies-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.technologies-inner-container {
    width: 82%;
    margin: auto;
}

.technologies-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.technologies-text-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.technologies-text {
    font-size: 0.8rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    color: #096bd8 !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.technologies-large-text-container {
    margin-top: 1.5rem;
}

.technologies-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.technologies-swiper-container {
    margin-top: 2rem;
}

.technologies-swiper-image {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
    .technologies-large-text {
        font-size: 2rem !important;
        text-align: center !important;
        font-weight: 600 !important;
        line-height: 2rem !important;
    }
}

.our-testimonials-container {
    background-color: #f8f8f8;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.our-testimonials-inner-container {
    width: 82%;
    margin: auto;
}

.our-testimonials-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-testimonials-line {
    height: 2.5px;
    width: 40px;
    display: inline-block;
    background: #096bd8;
}

.our-testimonials-text {
    font-size: 0.8rem !important;
    color: #096bd8 !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.our-testimonials-large-text-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 61%;
}

.our-testimonials-large-text {
    font-size: 3rem !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.our-testimonials-swiper-container {
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.our-testimonials-testimonial-container {
    padding: 3rem;
    background-color: white;
}

.our-testimonials-testimonial-text {
    color: #696969 !important;
    font-size: 0.9rem !important;
    line-height: 2.2rem !important;
    font-family: Montserrat !important;
    font-weight: 500 !important;
}

.our-testimonials-testimonial-rating-container {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
}

.our-testimonials-testimonial-rating {
    color: #f90 !important;
}

.our-testimonials-testimonial-user-profile-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-testimonials-testimonial-user-profile-text-container {
    margin-left: 1rem;
}

.our-testimonials-testimonial-user-name-text {
    font-size: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}

.our-testimonials-testimonial-user-profile-text {
    text-transform: uppercase !important;
    margin-top: 0.1rem !important;
    font-size: 0.8rem !important;
    color: #f90 !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.fix-me {
    height: 270px;
}

@media only screen and (max-width: 1250px) {
    .our-testimonials-large-text-container {
        width: 70%;
    }
}

@media only screen and (max-width: 1130px) {
    .our-testimonials-large-text-container {
        width: 80%;
    }
}

@media only screen and (max-width: 991px) {
    .our-testimonials-large-text-container {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .our-testimonials-large-text-container {
        width: 100%;
    }

    .our-testimonials-large-text {
        font-size: 1.9rem !important;
        line-height: 2.5rem !important;
    }

    .our-testimonials-testimonial-container {
        padding: 2rem;
    }

    .our-testimonials-testimonial-text {
        font-size: 0.8rem !important;
        line-height: 2rem !important;
    }

    .our-testimonials-testimonial-rating-container {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }

    .our-testimonials-testimonial-user-name-text {
        font-size: 1rem !important;
    }

    .our-testimonials-testimonial-user-profile-text {
        font-size: 0.7rem !important;
    }

    .fix-me {
        height: 600px;
    }
}

.clients-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.clients-inner-container {
    width: 82%;
    margin: auto;
}

.clients-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clients-text-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.clients-text {
    font-size: 0.8rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    color: #096bd8 !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 900 !important;
}

.clients-large-text-container {
    margin-top: 1.5rem;
}

.clients-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.clients-cards-container {
    margin-top: 2.5rem;
}

.clients-cards-inner-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
}

.clients-card-container {
    text-align: center;
    border: 1px solid #ccc;
    padding: 1rem 2.5rem;
}

.clients-card-image {
    height: 100px;
    max-width: 100%;
    object-fit: scale-down;
}

.client-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.5rem;
}

.client-card-container {
    border: solid 1px #ccc;
    height: 150px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-card-image {
    /* padding: 1.3rem 1rem;
    width: 181px;
    height: 100px;
    display: block;
    object-fit: scale-down; */
    height: 55px;
    max-width: 55%;
    object-fit: scale-down;
    /* filter: grayscale(1) !important; */
    /* -webkit-filter: grayscale(1); */
    /* transition: all 0.3s ease-in-out; */
}

.client-card-container:hover .client-card-image {
    cursor: pointer;
    filter: grayscale(0) !important;
    -webkit-filter: grayscale(0);
}

@media only screen and (max-width: 1240px) {
    .clients-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
    }

    .client-cards-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.5rem;
    }

    .client-card-container {
        height: 100px;
        min-width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .client-card-image {
        padding: 1rem 1rem;
        width: 181px;
        height: 100px;
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    .clients-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
    }

    .client-cards-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.5rem;
    }

    .client-card-container {
        height: 140px;
        min-width: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .client-card-image {
        padding: 1rem 1rem;
        width: 250px;
        height: 100px;
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .clients-large-text {
        font-size: 2rem !important;
        text-align: center !important;
        font-weight: 600 !important;
        line-height: 2rem !important;
    }

    .clients-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.8rem;
    }

    .client-cards-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0.5rem;
    }

    .client-card-container {
        height: 100px;
        min-width: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .client-card-image {
        padding: 1rem 1rem;
        width: 200px;
        height: 100px;
        display: block;
    }
}

.contactus-container {
    background-color: #096bd8;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.contactus-inner-container {
    width: 82%;
    margin: auto;
}

.contactus-items-container {
    display: flex;
}

.contactus-form-container {
    flex: 60% 1;
}

.contactus-form-inner-container {
    width: 95%;
}

.contactus-image-container {
    flex: 50% 1;
}

.contactus-image {
    width: 100%;
    height: auto;
    margin-left: 4rem;
}

.contactus-text-container {
    display: flex;
    align-items: center;
}

.contactus-line {
    height: 2.5px;
    width: 50px;
    display: inline-block;
    background: white;
}

.contactus-text {
    font-size: 0.8rem !important;
    color: white !important;
    margin-left: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.contactus-details-text {
    font-size: 0.8rem !important;
    color: white !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.contactus-details-text-cname {
    font-size: 1rem !important;
    color: white !important;
    margin-top: 12px !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}

.contactus-details-text-office-address {
    font-size: 1rem !important;
    color: white !important;
    margin-top: 12px !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}

.contactus-large-text-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 85%;
}

.contactus-large-text {
    font-size: 3rem !important;
    line-height: 3.8rem !important;
    color: white !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.contactus-send-button {
    padding: 1rem 2rem !important;
    background-color: white !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.contactus-button-icon {
    margin-top: 0.1rem;
    margin-left: 0.6rem;
}

.MuiInputLabel-root {
    font-family: Montserrat !important;
    font-weight: 500 !important;
}

.MuiInputLabel-root.Mui-focused {
    font-family: Montserrat !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.54) !important;
}

.contactus-form-inner-container .MuiInputBase-input {
    color: white !important;
    font-family: Montserrat !important;
}

.contactus-form-inner-container .MuiInputLabel-outlined {
    color: white !important;
}

.contactus-form-inner-container .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: white !important;
}

@media only screen and (max-width: 1250px) {
    .contactus-image {
        width: 100%;
        height: auto;
        margin-left: 2rem;
    }

    /* .contactus-image-container {
      flex: 50%;
      margin-top: 4rem;
    } */
}

@media only screen and (max-width: 1130px) {
    .contactus-image {
        width: 100%;
        height: auto;
        margin-left: 1rem;
    }

    .contactus-large-text {
        font-size: 2.5rem !important;
        font-weight: 600 !important;
        line-height: 3.8rem !important;
        color: white !important;
    }

    /* .contactus-image-container {
      flex: 50%;
      margin-top: 4rem;
    } */
}

@media only screen and (max-width: 991px) {
    .contactus-large-text-container {
        margin-top: 1rem;
        margin-bottom: 2rem;
        width: 60%;
    }

    .contactus-items-container {
        display: flex;
        flex-direction: column;
    }

    .contactus-form-container {
        flex: 100% 1;
    }

    .contactus-form-inner-container {
        width: 100%;
    }

    .contactus-image-container {
        flex: 100% 1;
        margin-top: 2rem;
        height: 400px;
    }
}

@media only screen and (max-width: 767px) {
    .contactus-large-text-container {
        margin-top: 1rem;
        margin-bottom: 2rem;
        width: 100%;
    }

    .contactus-large-text {
        font-size: 2rem !important;
        font-weight: 600 !important;
        line-height: 2.8rem !important;
        color: white !important;
    }

    .contactus-items-container {
        display: flex;
        flex-direction: column;
    }

    .contactus-form-container {
        flex: 100% 1;
    }

    .contactus-form-inner-container {
        width: 100%;
    }

    /* .contactus-image-container {
      flex: 100%;
      margin-top: 2rem;
      width: 100%;
      height: 350px;
    } */
}

.ReactGridGallery_tile {
    background-color: white !important;
}

.ReactGridGallery_tile-viewport {
    border-radius: 15px !important;
}

.ReactGridGallery {
    margin-left: 3rem;
}

/* img {
  margin-left: 0px !important;
  border-radius: 15px ;
  object-fit:fill;
} */
.gallery-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.gallery-inner-container {
    width: 82%;
    margin: auto;
}

.gallery-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-text-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.gallery-text {
    font-size: 0.8rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    color: #096bd8 !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.gallery-card-container {
    text-align: center;
    border: 1px solid #ccc;
    padding: 1rem 2.5rem;
}

.gallery-cards-container {
    display: flex;
    flex-direction: column;
}

.gallery-card-container {
    border: solid 1px #ccc;
    height: 140px;
    min-width: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1240px) {
    .gallery-cards-container {
        display: flex;
        flex-direction: column;
    }

    .gallery-card-container {
        height: 100px;
        min-width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media only screen and (max-width: 991px) {
    .gallery-cards-container {
        display: flex;
        flex-direction: column;
    }

    .gallery-card-container {
        height: 140px;
        min-width: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media only screen and (max-width: 767px) {
    .gallery-large-text {
        font-size: 2rem !important;
        text-align: center !important;
        font-weight: 600 !important;
        line-height: 2rem !important;
    }

    .gallery-cards-container {
        display: flex;
        flex-direction: column;
    }

    .gallery-card-container {
        height: 100px;
        min-width: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
  

.working-process-cards-container {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 991px) {
    .working-process-cards-container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
    }
}

@media only screen and (max-width: 767px) {
    .working-process-cards-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3rem;
    }
}

.blog-page-container {
    background-color: #f4f9fc;
}

.blog-page-inner-container {
    width: 82%;
    margin: auto;
}

.blog-page-blog-outer-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.blog-page-blog-container {
    padding: 4rem 6rem;
}

.blog-page-blog-main-heading {
    font-size: 3rem !important;
    text-align: center !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
    width: 100% !important;
    margin: auto !important;
}

.blog-page-profile-and-share-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    margin-top: 2rem;
}

.blog-page-share-container {
    margin-top: 0;
}

.blog-page-blog-title {
    font-size: 1.2rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 600 !important;
    line-height: 2rem !important;
}

.blog-page-blog-body {
    margin-top: 1rem !important;
    font-size: 1rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    line-height: 2rem !important;
}

.blog-page-blog-comment-profile {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.blog-page-blog-comment-profile-text-container {
    margin-left: 0.8rem;
}

.blog-page-blog-comment-profile-text {
    font-size: 0.8rem !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 400 !important;
    width: 80% !important;
}

.blog-page-blog-body-share-container {
    margin-top: 2rem;
}

@media only screen and (max-width: 1250px) {
    .blog-page-blog-main-heading {
        font-size: 2rem !important;
    }
}

@media only screen and (max-width: 991px) {
    .blog-page-blog-main-heading {
        font-size: 1.5rem !important;
    }
}

@media only screen and (max-width: 767px) {
    .blog-page-inner-container {
        width: 95%;
        margin: auto;
    }

    .blog-page-blog-container {
        padding: 0 0;
    }

    .blog-page-blog-main-heading {
        font-size: 1.4rem !important;
    }

    .blog-page-profile-and-share-container {
        flex-direction: column;
        margin-top: 1.5rem;
    }

    .blog-page-share-container {
        margin-top: 0.5rem;
    }

    .blog-page-blog-title {
        font-size: 1.1rem !important;
    }

    .blog-page-blog-body {
        margin-top: 1rem !important;
        font-size: 0.9rem !important;
        font-family: Montserrat, sans-serif !important;
        font-weight: 400 !important;
    }

    .blog-page-blog-comment-profile {
        align-items: flex-start;
        flex-direction: column;
    }

    .blog-page-blog-comment-profile-text-container {
        margin-left: 0;
    }

    .blog-page-blog-comment-profile-text {
        width: 100% !important;
    }

    .blog-page-blog-body-share-container {
        text-align: center;
    }
}

.MuiContainer-maxWidthLg {
    max-width: 1222px !important;
}

.MuiTypography-h6 {
    font-weight: 600 !important;
}

.MuiButton-containedPrimary {
    background-color: #2c74d6 !important;
}

.job-name .MuiTypography-body1 {
    font-size: 2.6rem !important;
    font-weight: 600 !important;
    letter-spacing: 0.00938em !important;
    line-height: 2.5rem !important;
    margin-top: 11px !important;
    /* transition: all .4s ease !important; */
}

.job-specification .MuiTypography-body1 {
    font-family: system-ui !important;
    font-weight: 100 !important;
}

.job-name-on-scroll .MuiTypography-body1 {
    font-size: 2.5rem !important;
    font-weight: 700 !important;
    letter-spacing: 0.00938em !important;
}

.showed-on-scroll-grid .MuiContainer-root {
    /* padding-top: 24px !important; */
}

.job-name-on-scroll {
    transition: all .4s ease !important;
}

.text-font-for-details {
    font-size: 15px !important;
    line-height: 30px !important;
    color: #696969 !important;
    font-family: Montserrat !important;
    font-weight: 400 !important;
}

.heading-of-details {
    font-size: 0.8rem !important;
    color: black !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.mail-address-details {
    font-size: 1rem !important;
    color: white !important;
    margin-top: 12px !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}
.job-labels {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px !important;
    margin: 24px 0 24px 0 !important;
    width: auto !important;
    /* height: 300px !important; */
}

.job-content {
    border: 1px solid #ccc !important;
    border-radius: 8px !important;
    padding: 16px 16px 30px !important;
    cursor: pointer !important;
}

.job-content:hover {
    background-color: #286dcb !important;
    cursor: pointer !important;
}

.job-content:hover .job-title {
    color: #ffff !important;
}

.job-content:hover .view-details-text {
    color: #fff !important;
}

.job-content:hover .job_label {
    color: #ddd !important;
}

.job-title {
    /* font-family: montserratmedium !important; */
    line-height: 1.5 !important;
    font-size: 1.5rem !important;
    height: 64px !important;
    font-weight: 600 !important;
    padding-top: 4px !important;
    color: #000 !important;
    text-decoration: none !important;
}

.job-title-on-hover {
    /* font-family: montserratmedium !important; */
    line-height: 1.5 !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    padding-top: 4px !important;
    color: #fff !important;
}

.job_label {
    height: 64px !important;
    font-size: 0.9rem !important;
    line-height: 1.45em !important;
    margin: 16px 0 !important;
    color: #444 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    font-weight: 300 !important;
    text-decoration: none !important;
}

.view-details-text {
    text-transform: uppercase !important;
    color: #286dcb !important;
    font-size: 0.9rem !important;
    line-height: 1.5em !important;
    position: absolute !important;
    /* bottom: 10px !important; */
    font-weight: 600 !important;
}

.underline-remove {
    text-decoration: none !important;
}

.go-back-btn {
    cursor: pointer;
}

.header-container {
    background-color: rgb(11, 11, 60) !important;
    color: #fff;
    display: grid;
    grid-template-columns: minmax(100%, 1200px) !important;
    margin: 0 auto !important;
    /* max-width: 1200px !important; */
}

.banner-container {
    padding: 20px 0 20px 0 !important;
}

.back-icon-height {
    font-size: 56px !important;
}

.banner-label-title {
    font-family: montserratmedium !important;
}

.header-title {
    font-size: 2.6rem !important;
    font-weight: 600 !important;
    letter-spacing: 0.00938em !important;
    line-height: 2.5rem !important;
}

.header-title.MuiTypography-body1 {
    letter-spacing: normal !important;
}

@media only screen and (max-width: 768px) {
    .job-labels {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 0.5rem;
        margin: 24px 0 24px 0 !important;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

