.clients-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.clients-inner-container {
    width: 82%;
    margin: auto;
}

.clients-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clients-text-line {
    height: 2.5px;
    width: 40px;
    background: #096bd8;
    display: inline-block;
}

.clients-text {
    font-size: 0.8rem !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    color: #096bd8 !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 900 !important;
}

.clients-large-text-container {
    margin-top: 1.5rem;
}

.clients-large-text {
    font-size: 3rem !important;
    text-align: center !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.clients-cards-container {
    margin-top: 2.5rem;
}

.clients-cards-inner-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
}

.clients-card-container {
    text-align: center;
    border: 1px solid #ccc;
    padding: 1rem 2.5rem;
}

.clients-card-image {
    height: 100px;
    max-width: 100%;
    object-fit: scale-down;
}

.client-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.5rem;
}

.client-card-container {
    border: solid 1px #ccc;
    height: 150px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-card-image {
    /* padding: 1.3rem 1rem;
    width: 181px;
    height: 100px;
    display: block;
    object-fit: scale-down; */
    height: 55px;
    max-width: 55%;
    object-fit: scale-down;
    /* filter: grayscale(1) !important; */
    /* -webkit-filter: grayscale(1); */
    /* transition: all 0.3s ease-in-out; */
}

.client-card-container:hover .client-card-image {
    cursor: pointer;
    filter: grayscale(0) !important;
    -webkit-filter: grayscale(0);
}

@media only screen and (max-width: 1240px) {
    .clients-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
    }

    .client-cards-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.5rem;
    }

    .client-card-container {
        height: 100px;
        min-width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .client-card-image {
        padding: 1rem 1rem;
        width: 181px;
        height: 100px;
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    .clients-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
    }

    .client-cards-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.5rem;
    }

    .client-card-container {
        height: 140px;
        min-width: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .client-card-image {
        padding: 1rem 1rem;
        width: 250px;
        height: 100px;
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .clients-large-text {
        font-size: 2rem !important;
        text-align: center !important;
        font-weight: 600 !important;
        line-height: 2rem !important;
    }

    .clients-cards-inner-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.8rem;
    }

    .client-cards-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0.5rem;
    }

    .client-card-container {
        height: 100px;
        min-width: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .client-card-image {
        padding: 1rem 1rem;
        width: 200px;
        height: 100px;
        display: block;
    }
}
