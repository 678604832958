.our-testimonials-container {
    background-color: #f8f8f8;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.our-testimonials-inner-container {
    width: 82%;
    margin: auto;
}

.our-testimonials-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-testimonials-line {
    height: 2.5px;
    width: 40px;
    display: inline-block;
    background: #096bd8;
}

.our-testimonials-text {
    font-size: 0.8rem !important;
    color: #096bd8 !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 900 !important;
}

.our-testimonials-large-text-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 61%;
}

.our-testimonials-large-text {
    font-size: 3rem !important;
    line-height: 3.8rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.our-testimonials-swiper-container {
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.our-testimonials-testimonial-container {
    padding: 3rem;
    background-color: white;
}

.our-testimonials-testimonial-text {
    color: #696969 !important;
    font-size: 0.9rem !important;
    line-height: 2.2rem !important;
    font-family: Montserrat !important;
    font-weight: 500 !important;
}

.our-testimonials-testimonial-rating-container {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
}

.our-testimonials-testimonial-rating {
    color: #f90 !important;
}

.our-testimonials-testimonial-user-profile-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-testimonials-testimonial-user-profile-text-container {
    margin-left: 1rem;
}

.our-testimonials-testimonial-user-name-text {
    font-size: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
}

.our-testimonials-testimonial-user-profile-text {
    text-transform: uppercase !important;
    margin-top: 0.1rem !important;
    font-size: 0.8rem !important;
    color: #f90 !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
}

.fix-me {
    height: 270px;
}

@media only screen and (max-width: 1250px) {
    .our-testimonials-large-text-container {
        width: 70%;
    }
}

@media only screen and (max-width: 1130px) {
    .our-testimonials-large-text-container {
        width: 80%;
    }
}

@media only screen and (max-width: 991px) {
    .our-testimonials-large-text-container {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .our-testimonials-large-text-container {
        width: 100%;
    }

    .our-testimonials-large-text {
        font-size: 1.9rem !important;
        line-height: 2.5rem !important;
    }

    .our-testimonials-testimonial-container {
        padding: 2rem;
    }

    .our-testimonials-testimonial-text {
        font-size: 0.8rem !important;
        line-height: 2rem !important;
    }

    .our-testimonials-testimonial-rating-container {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }

    .our-testimonials-testimonial-user-name-text {
        font-size: 1rem !important;
    }

    .our-testimonials-testimonial-user-profile-text {
        font-size: 0.7rem !important;
    }

    .fix-me {
        height: 600px;
    }
}
